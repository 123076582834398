<template>
  <div>
    <subdivisions-edit></subdivisions-edit>
  </div>
</template>

<script>
import SubdivisionsEdit from "@/components/ecologist/subdivisions/subdivisionsEdit";
export default {
  name: "subdivisionsEditView",
  components: { SubdivisionsEdit },
};
</script>

<style scoped></style>
