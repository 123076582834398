<template>
  <div>
    <el-form
      :rules="rules"
      :model="selectedSubDivision"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="title" label="Наименование подразделения">
            <el-input v-model="selectedSubDivision.title"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="email" label="Почта">
            <el-input v-model="selectedSubDivision.email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Фактический адрес нахождения подразделения</h4>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="region" label="Область">
            <el-select
              clearable
              @change="changeArea"
              class="searchPole"
              v-model="selectedSubDivision.areaInfo"
              value-key="id"
              placeholder="Область"
            >
              <el-option
                v-for="item in areaNames.content"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="district" label="Район">
            <el-select
              clearable
              @change="changeDistrict"
              :disabled="selectedSubDivision.areaInfo === null"
              class="searchPole"
              v-model="selectedSubDivision.districtInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Район"
              :remote-method="searchDistrictNames"
            >
              <el-option
                v-for="item in districtNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="locality" label="Населенный пункт">
            <el-select
              clearable
              :disabled="selectedSubDivision.districtInfo === null"
              class="searchPole"
              v-model="selectedSubDivision.cityInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Населенный пункт"
              :remote-method="searchCitiesNames"
            >
              <el-option
                v-for="item in citiesNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="street" label="Улица">
            <el-input v-model="selectedSubDivision.street"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="phoneNumber" label="Телефон">
            <el-input v-model="selectedSubDivision.phoneNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="parentInfo" label="Объект/Филиал">
            <el-select
              clearable
              class="searchPole"
              v-model="selectedSubDivision.parentInfo"
              value-key="id"
              placeholder="Объект/Филиал"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="selectedSubDivision.dateFrom"
                  type="date"
                  placeholder = "Дата начала работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <br>
      <el-row>
        <input type="checkbox" id="checkbox" v-model="selectedSubDivision.datEd">
        <label for="checkbox">Устаревшее</label>
      </el-row>
      <el-row >
        <el-date-picker 
                  v-model="selectedSubDivision.dateTo"
                  type="date"
                  :disabled="!selectedSubDivision.datEd"
                  placeholder = "Выберите дату"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <el-row>
        <el-button @click="save" type="primary">Добавить</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "subdivisionsEdit",
  props: ["selectedSubDivision"],
  data() {
    return {
      rules: {
        title: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        parentInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        cityInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        districtInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        areaInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        responsiblePersonsInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      areaNames: "GETLISTALLAREAS",
      districtNames: "GETLISTDISTRICNAMES",
      citiesNames: "GETLISTCITIESNAMES",
      responsiblePersonList: "GETRESPONSIBLEPERSONLIST",
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  methods: {
    searchResponsiblePerson(query) {
      this.searchResponsiblePersonByQuery(query);
    },

    searchDistrictNames(query) {
      this.getDistrictNames(query);
    },

    searchCitiesNames(query) {
      this.getCitiesNames(query);
    },

    changeArea() {
      this.searchDistrictNames("");
    },

    changeDistrict() {
      this.searchCitiesNames("");
    },

    save() {
      this.selectedSubDivision.areaId = this.selectedSubDivision.areaInfo.id != -1 && this.selectedSubDivision.areaInfo !="" ? this.selectedSubDivision.areaInfo.id : null;
      this.selectedSubDivision.districtId = this.selectedSubDivision.districtInfo.id!= -1 && this.selectedSubDivision.districtInfo !="" ?  this.selectedSubDivision.districtInfo.id : null;
      this.selectedSubDivision.areaInfo = this.selectedSubDivision.areaInfo !="" ? this.selectedSubDivision.areaInfo : null;
      this.selectedSubDivision.districtInfo = this.selectedSubDivision.districtInfo !="" ? this.selectedSubDivision.districtInfo : null;
      this.selectedSubDivision.cityInfo = this.selectedSubDivision.cityInfo !=""? this.selectedSubDivision.cityInfo : {id:-1};
      this.selectedSubDivision.street = this.selectedSubDivision.street !="" ? this.selectedSubDivision.street : null;
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("updateOrganizationUnitInfo", this.selectedSubDivision)
            .then(() => {
              this.notification(
                "Успешно",
                "Подразделение добавлено",
                "success"
              );
              this.$store.dispatch("getListUnits", 2);
              this.$store.dispatch("setShowModal", null);
            });
        }
      });
    },

    getListAllAreas() {
      this.$store.dispatch("getListAllAreas", {
        page: 0,
        size: 20,
      });
    },

    getDistrictNames(query) {
      this.$store.dispatch("getDistrictNames", {
        areaId: this.selectedSubDivision.areaInfo.id,
        query: query,
      });
    },

    getCitiesNames(query) {
      this.$store.dispatch("getCitiesNamesForUser", {
        areaId: this.selectedSubDivision.areaInfo.id,
        districtId: this.selectedSubDivision.districtInfo.id,
        query: query,
      });
    },

    searchResponsiblePersonByQuery(query) {
      this.$store.dispatch("searchResponsiblePerson", {
        page: 0,
        size: 20,
        query: query,
      });
    },

    getAllResponsiblePerson() {
      this.$store.dispatch("getAllResponsiblePerson", { page: 0, size: 20 });
    },

    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 1,
      });
    },
  },

  mounted() {
    this.getOrganizationHierarchyByType();
    this.getListAllAreas();
    this.getAllResponsiblePerson();
  },
};
</script>

<style scoped></style>
